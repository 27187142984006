import React from "react";
import '../../scss/slidewithblocks.scss';
import '../../scss/reviews.scss';

const SlideReviews = (props) => {

    const {slide} = props;

    return (
        <div className="SlideReviews">
            
            {slide && (
                <>
                    <div className="up">
                        {slide?.reviews?.up && (
                            <div>
                                {slide?.reviews?.up?.img && <img src={slide.reviews.up.img} />}
                                {slide?.reviews?.up?.text && <p>{slide.reviews.up.text}</p>}
                            </div>
                        )}
                    </div>
                    <div className="down">
                        {slide?.reviews?.down && (
                            <div>
                                {slide?.reviews?.down?.text && <p>{slide.reviews.down.text}</p>}
                                {slide?.reviews?.down?.who && <p>{slide.reviews.down.who}</p>}
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default SlideReviews;