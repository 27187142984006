import React, {useState} from "react";

const SlideApps = (props) => {

    const {text, ico, qr} = props;
    const [selectedQR, setSelectedQR] = useState('ios');

    const correctQR = qr.find((itm) => itm?.code === selectedQR);

    return (
        <div className="SlideApps txt">
            <div className="icon">
                {ico && <img src={ico} />}     
            </div>
            <div className="text">
                <div dangerouslySetInnerHTML={{__html: text}}></div>
            </div>
            <div className="qr">
                {qr?.length>0 && (
                    <>
                        <p>Download the app to your device now!</p>
                        <div className="btns">
                            {qr.map((itm, index) => (
                                <button key={index} className={itm?.code === selectedQR ? 'active' : ''} onClick={() => setSelectedQR(itm?.code)}>{itm?.label}</button>
                            ))}
                        </div>
                        {correctQR && <div><img src={correctQR.img} /></div>}
                    </>
                )}
            </div>
        </div>
    )
}

export default SlideApps;