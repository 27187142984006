import React from "react";
import '../../scss/slidewithblocks.scss'

const SlideWithBlocks = (props) => {

    const {text, blocks} = props;

    return (
        <div className="SlideWithBlocks txt">
            <div dangerouslySetInnerHTML={{__html: text}}></div>
            {blocks && (
                <div className="blocks">
                    {blocks.map((itm) => {
                        return (
                            <div style={{background: itm.bg}}>
                                {itm.img && <img src={itm.img} />}
                                {itm.title && <h2>{itm.title}</h2>}
                                {itm.twoLines && itm.twoLines.length>0 && (
                                    <div className="twoLines">
                                        <h2>{itm.twoLines[0]}</h2>
                                        <p>{itm.twoLines[1]}</p>
                                    </div>
                                )}
                                {itm.text && <p>{itm.text}</p>}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default SlideWithBlocks;