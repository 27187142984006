import React from "react";
import SlideApps from '../editorial/SlideApps';

const SlideMixed = (props) => {
    const {item} = props;

    // DEFAULT
    let slide = (
        <div className={`SlideMixed ${item?.type || 'SlideCentered'} ${item?.whiteText ? 'whiteText' : ''} txt`}>
            <div dangerouslySetInnerHTML={{__html: item?.text}}></div>
        </div>
    );


    // IF TYPE IS APP
    if (item?.type==='app') {
        slide = (
            <SlideApps
                whiteText={item?.whiteText}
                text={item?.text} 
                qr={item?.qr} 
                ico={item?.ico} 
            />
        )
    }

    // ETC

    return slide;
}

export default SlideMixed;