import React from "react";

const SlideFlex = (props) => {

    const {text, textWithImage} = props;

    return (
        <div className='flexContent'>
            {text && (
                <div className="txt">
                    <div dangerouslySetInnerHTML={{__html: text}}></div>
                </div>
            )}
            {(textWithImage.img || textWithImage?.subtitle) && (
                <div className="img">
                    {textWithImage?.subtitle && <p>{textWithImage?.subtitle}</p>}
                    {textWithImage?.img && <img src={textWithImage?.img} />}
                </div>
            )}
        </div>
    )
}

export default SlideFlex;