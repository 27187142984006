import React from "react";
import Carousel from "../../../components/editorial/CarouselVideo";
import data from '../../../../queries/data/q';
import Layout from "../../../UI/Layout";

const index = () => {

  const carousel = {
    mainCarousel : [
      ...data.data.wp.q.options
    ]
  }

  const dataHeader = data.data.wp.q.dataHeader;

  console.log(dataHeader);

  return (
    <Layout className="Editorial" dataHeader={dataHeader}>
      <Carousel noScroll noTitle info={carousel}/>
    </Layout>
  )
}

export default index;