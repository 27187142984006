import React from "react";

const SlideCentered = (props) => {
    const {text, whiteText} = props;

    return (
        <div className={`${whiteText ? 'whiteText' : ''} SlideCentered txt`}>
            <div dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
    )
}

export default SlideCentered;