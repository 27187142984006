import React, {useState, useRef} from 'react';
import Flickity from "react-flickity-component";
import scrollDown from '../../images/arrow-black.svg';
import Vimeo from '@u-wave/react-vimeo';
import SlideCentered from '../editorial/SlideCentered';
import SlideFlex from '../editorial/SlideFlex';
import SlideMixed from '../editorial/SlideMixed';
import SlideApps from '../editorial/SlideApps';
import SlideWithBlocks from '../editorial/SlideWithBlocks';
import SlideReviews from '../editorial/SlideReviews';

import "flickity/dist/flickity.min.css";

const Carousel = (props) => {

    const {info, noTitle, noScroll, title, scrollText, flexSlides, withBlocks, appSlides, whiteText, reviewsSlides, mixedSlides, slideType} = props;

    const [active, setActive] = useState(0);
    const flRef = useRef(); 


    const chooseCorrectSlide = (slide) => {

        const {item, whiteText, info, slideType} = slide;

        switch (slideType) {
            case 'flexSlides':
                return <SlideFlex
                    text={item?.text} 
                    textWithImage={item?.textWithImage}
                    whiteText={whiteText}
                />
            case 'appSlides':
                return <SlideApps
                    text={item?.text} 
                    qr={item?.qr} 
                    ico={item?.ico} 
                    whiteText={whiteText}
                />

            case 'withBlocks':
                return <SlideWithBlocks
                    text={item?.text} 
                    blocks={item?.blocks}
                    whiteText={whiteText}
                />

            case 'reviewsSlides':
                return <SlideReviews
                    titles={info.titles}
                    slide={item} 
                    whiteText={whiteText}
                />
                
            case 'mixedSlides':
                return <SlideMixed
                    titles={info?.titles}
                    item={item}
                />

        
            default:
                return <SlideCentered
                    whiteText={whiteText} 
                    text={item?.text} 
                />

        }
        
    }

    return (
        <>
        <div className="Carousel CarouselVideo">
            {!noTitle && (
                <div className="txt">
                    <div dangerouslySetInnerHTML={{__html: info?.mainText}}></div>
                </div>
            )}
            <div className="img">
            {title && <h2 className='mainTitle'>{title}</h2>}

            {slideType === 'reviewsSlides' && (
                <>
                    {info?.titles?.up && <h3 className='reviewsTitle'>{info.titles.up}</h3>}
                    {info?.titles?.down && <h2 className='reviewsTitle'>{info.titles.down}</h2>}
                </>
            )}


            {info?.mainCarousel.length > 0 && (
                <Flickity
                    flickityRef={() => {}}
                    ref={flRef}
                    static
                    className={"main-carousel"}
                    elementType={"div"}
                    options={{
                        contain: true,
                        // autoPlay:5000,
                        autoPlay: false, 
                        prevNextButtons: true,
                        contain: true,
                        wrapAround:true,
                        arrowShape : "M69.5719282,7.87947368 C69.7298915,8.07090526 69.8725821,8.24023158 70,8.39452632 L31.4111422,45.9734737 L70,83.5789474 C69.8725821,83.7292632 69.7298915,83.9251158 69.5719282,84.1169895 C68.7812391,85.0454105 66.4475717,88 64.6999393,88 C63.9363047,87.9659579 63.2922334,87.6693053 62.6595076,87.0640632 L23.0561067,48.4810947 C21.6479644,47.1088 21.6479644,44.8836842 23.0561067,43.5153684 L62.6595076,4.9324 C63.2922334,4.32317895 63.9363047,4.02652632 64.6999393,4 C66.4475717,4 68.7812391,6.94707368 69.5719282,7.87947368 Z",
                        // pauseAutoPlayOnHover:false,
                        on: {
                            ready: function() {
                                setActive(0);
                                // const iframe = document.getElementById(`slide-${active}`);
                                // const player = new Vimeo.Player(iframe);

                                // player.on('play', function() {
                                //     console.log('played the video!');
                                // });
                            },
                            change: function( index ) {
                                //console.log(index);
                                // flRef && flRef.current && flRef.current.flkty.player.play()
                                setTimeout(() => {
                                    setActive(index);
                                }, 1);
                            },
                        }
                    }}
                >
                    {info?.mainCarousel.length>0 && info?.mainCarousel.map((item, i) => (
                    <div className={item?.image?.mediaItemUrl ? 'carousel-cell' : 'noImage carousel-cell'} key={i}>
                        {item?.image?.mediaItemUrl && (
                            !item?.vimeoId && (
                                <img
                                    src={item?.image?.mediaItemUrl}
                                    key={i}
                                />
                            )
                        )}
                        
                            {item?.vimeoId && 
                                i === active && 
                                
                                    <Vimeo 
                                        id={`slide-${i}`}
                                        className="vimeoPlayer" 
                                        video={Number(item.vimeoId)} 
                                        controls={false} 
                                        autoplay={false} 
                                        muted={true} 
                                        onReady={(v) => {
                                            v.play();
                                        }}
                                        onEnd={(v) =>{
                                            //console.log(v);
                                        }}
                                        onPlay={(v) =>{
                                            //console.log(v);
                                        }}
                                    />
                                }

                        {chooseCorrectSlide({item, whiteText, info, slideType})}

                    </div>
                    ))}
                </Flickity>
            )}
            <div className={`${whiteText ? 'whiteText' : ''} counterCont`}>
                <div className="counter" style={{width:(info?.mainCarousel.length ) * 30,left: `calc(50% - ${((info?.mainCarousel.length ) * 30) / 2}px)`}}>
                    {info?.mainCarousel.map((item, i) => {
                        return (
                            <div key={i} className={i === active ? 'active' : ''}>
                                <div className="txt" style={{width:30}}>
                                    <div>
                                        <div className="bat">
                                        </div>
                                        <h6 style={{paddingTop:7}}>{i + 1}</h6>
                                    </div>
                                </div>
                            </div>
                            )
                        }        
                        )} 
                    </div>
                </div>
            </div>
            {!noScroll && (
                <div className="scrollDownCont">
                    <div className="scrollDown">
                        <img src={scrollDown} />
                        <h6>
                            {scrollText || 'Scroll to see all the unique colours'}
                        </h6>
                    </div>
                </div>
            )}
        </div>
    </>
    )
}

export default Carousel;

